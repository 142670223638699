<template>
  <div class="home">
    <div class="">
      <div class="container container--header">
        <div class="header">
          <router-link
            to="/"
            class="header__logo"
          />
          <div class="header-block">
            <p class="header-block__name">
              Добрый день!
            </p>
            <img
              src="@/assets/icons/name_icon.png"
              srcset="@/assets/icons/name_icon@2x.png 2x"
              alt=""
              class="header-block__icon"
            >
          </div>
        </div>
      </div>
      <div class="container">
        <div class="meditation">
          <h1 class="meditation__tit">
            Урок «Раскрой женственность»
          </h1>
          <p class="meditation__text">
            Урок от психолога-сексолога Евгении Липчинской, которая уже более 10 лет работает с парами, мужчинами и женщинами и помогает им раскрываться через сексуальность.
          </p>
          <p class="meditation__desc">
            Приготовьте ручку и листочек, чтобы сделать упражнение из урока.
          </p>
          <div class="meditation-blocks--wrapper">
            <div class="meditation-blocks">
              <div v-for="(video, index) in videos" class="meditation-block meditation-block--fitness" :key="video.id">
                <a
                  href="#"
                  @click.prevent="selectTrack(video, index)"
                >
                  <div class="meditation-block__img-block meditation-block__img-block--fitness">
                    <img
                      :src="video.image['2x'].replace('https://79f714d8-9b86-4071-b1da-ed403b2e9bbb.akamaized.net/', 'https://lerchek.fit/')"
                      alt="img"
                      class="meditation-block__img meditation-block__img--fitness"
                    >
                    <div class="meditation-block__info-play meditation-block__info-play--fitness" />
                  </div>

                </a>
                <div class="meditation-block__info meditation-block__info--fitness">
                  <div class="meditation-block__desc">
                    На уроке вы узнаете:
                  </div>
                  <div class="meditation-block__info-list">
                    <div class="meditation-block__info-item">
                      <span>—</span> Несколько правил, как научиться женственности.
                    </div>
                    <div class="meditation-block__info-item">
                      <span>—</span> Что такое женственность и сексуальность, чем они отличаются.
                    </div>
                    <div class="meditation-block__info-item">
                      <span>—</span> Почему современной женщине сложно быть женственной.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="products__wrapper">
              <div class="products-blocks">
                <div class="products-block">
                  <h5 class="products-block__tit">
                    Серия ROSE THERAPY
                  </h5>
                  <div class="products">
                    <a href="https://letique.ru/catalog/rose-therapy?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product.png"
                        srcset="@/assets/images/reveal-femininity/product@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Серия ROSE THERAPY
                      </p>
                    </a>
                  </div>
                </div>
                <div class="products-block">
                  <h5 class="products-block__tit">
                    Крем для тела
                  </h5>
                  <div class="products">
                    <a href="https://letique.ru/catalog/cream-vanilla-ecstasy?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_2.png"
                        srcset="@/assets/images/reveal-femininity/product_2@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Крем-суфле для тела VANILLA ECSTASY
                      </p>
                    </a>
                    <a href="https://letique.ru/catalog/butter-cherry?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_3.png"
                        srcset="@/assets/images/reveal-femininity/product_3@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Крем-баттер для тела БАРБАДОССКАЯ ВИШНЯ
                      </p>
                    </a>
                    <a href="https://letique.ru/catalog/butter-marakuya?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_4.png"
                        srcset="@/assets/images/reveal-femininity/product_4@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Крем-баттер для тела МАНГО-МАРАКУЙЯ
                      </p>
                    </a>
                    <a href="https://letique.ru/catalog/butter-frangipani-monoi?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_5.png"
                        srcset="@/assets/images/reveal-femininity/product_5@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Крем-баттер для тела ФРАНЖИПАНИ-МОНОИ
                      </p>
                    </a>
                  </div>
                </div>
                <div class="products-block">
                  <h5 class="products-block__tit">
                    Скраб для тела
                  </h5>
                  <div class="products">
                    <a href="https://letique.ru/catalog/cocoa-scrub?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_6.png"
                        srcset="@/assets/images/reveal-femininity/product_6@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Скраб для тела КАКАО
                      </p>
                    </a>
                    <a href="https://letique.ru/catalog/lagoon-scrub?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_7.png"
                        srcset="@/assets/images/reveal-femininity/product_7@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Скраб для тела LAGOON SCRUB
                      </p>
                    </a>
                    <a href="https://letique.ru/catalog/scrub-vanilla-ecstasy?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_8.png"
                        srcset="@/assets/images/reveal-femininity/product_8@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Скраб для тела VANILLA ECSTASY
                      </p>
                    </a>
                    <a href="https://letique.ru/catalog/scrub-black-currant?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="product" target="_blank">
                      <img
                        src="@/assets/images/reveal-femininity/product_9.png"
                        srcset="@/assets/images/reveal-femininity/product_9@2x.png 2x"
                        alt="img"
                        class="product__img"
                      >
                      <p class="product__name">
                        Cкраб для тела BLACK CURRANT
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div class="button__block">
                <a href="https://letique.ru/catalog/?utm_source=prize_wheel&utm_medium=prize_lk&utm_campaign=fem_lesson" class="button" target="_blank">
                  В каталог
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div
      v-if="video"
      class="player"
      @click.prevent="video = null"
    >
      <div
        class="player-block"
        @click.stop
      >
        <iframe
          allow="autoplay; fullscreen"
          allowfullscreen
          :src="video.url + '&autoplay=1'"
          class="vimeo"
          width="100%"
          height="100%"
        />
        <button
          class="player-block__close"
          @click.prevent="video = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      videos: [],
      video: null,
      currentTrack: null,
    };
  },
  computed: {
    bg() {
      if (!this.video) {
        return null;
      }
      return this.video.image['2x'];
    },
    title() {
      if (process.env.VUE_APP_PROJECT_TITLE) {
        return process.env.VUE_APP_PROJECT_TITLE;
      }
      return '';
    },
  },
  mounted() {
    this.load();
    document.documentElement.classList.add('bg--main');
  },
  methods: {
    async load() {
      const response = await this.$request.get('/api/guide/video', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/login');
      } else {
        this.videos = response.data.result.videos;
      }
    },
    selectTrack(video) {
      this.video = video;
    },
    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let sec = Math.floor(seconds - minutes * 60);
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      sec = sec < 10 ? `0${sec}` : sec;
      return `${minutes}:${sec}`;
    },
  },
};
</script>
<style lang="scss">

.player-block__close {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background: none;
  border: none;
  top: 0;
  &:before,
  &:after {
    content: ' ';
    width: 2px;
    background: #fff;
    height: 100%;
    transform: rotate(45deg);
    position: absolute;
  }
  &:after {
    transform: rotate(-45deg);
  }
  @media (max-width: 768px) {
    top: 0;
    right: 2rem;
  }
}
audio {
  display: none;
}
.meditation-block {
  &__img-block {
    overflow: hidden;
    & img {
      transition: .3s ease transform;
    }
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
    & .meditation-block__info-play:after {
      transform: translate(-50%, -50%) scale(0);
    }
  }
  &__info-play {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      width: 16rem;
      height: 16rem;
      background: rgba(255,255,255,.2);
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: .3s ease transform;
    }
    &--fitness {
      position: absolute;
    }
  }
}
.meditation-block__img {
  @media (max-width: 768px) {
    top: -2rem;
  }
  &--fitness {
    top: 0;
  }
}
.player {
  display: flex;
  &-block {
    height: 81.7rem;
    width: 83.4rem;
    box-sizing: border-box;
    max-height: 90vh;
    padding: 3.7rem 3.7rem 4.3rem 3.7rem;
    @media (max-width: 768px) {
      min-height: 100vh;
      width: 100%;
      padding: 1.8rem 1.8rem 7rem;
      &__name {
        padding-top: 15rem;
      }
    }
    &__like {
      display: none;
    }
  }
}
</style>
